//https://reactnavigation.org/docs/configuring-links/#passing-params
//important links: https://reactnavigation.org/blog/2020/05/16/web-support/

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { View, StyleSheet } from "react-native";
import NoFoundController from "../common/controllers/NoFoundController";
import routes from "./routes";
import DashboardController from "../common/controllers/DashboardController";
import SettingsController from "../settings/controllers/SettingsController";
import BroadcastController from "../maritime/controllers/BroadcastController";
import SystemMessageController from "../maritime/controllers/SystemMessageController";
import DialpadController from "../maritime/controllers/DialpadController";
import MarketingController from "../marketing/controllers/MarketingController";
import MessageController from "../maritime/controllers/MessageController";
import ChatController from "../maritime/controllers/ChatController";
import VoicemailController from "../maritime/controllers/VoicemailController";
import MarketingHealthController from "../marketing/controllers/MarketingHealthController";
import MarketingCrewAppController from "../marketing/controllers/MarketingCrewAppController";
import MarketingGuestAppController from "../marketing/controllers/MarketingGuestAppController";
import MarketingPBXSystemController from "../marketing/controllers/MarketingPBXSystemController";
import MarketingStarlinkController from "../marketing/controllers/MarketingStarlinkController";
import OmniChannelController from "../maritime/controllers/OmniChannelController";
import OmniChannelChatController from "../maritime/controllers/OmniChannelChatController";
import MessageV2Controller from "../maritime/controllers/MessageV2Controller";

const Stack = createNativeStackNavigator();

function AppNavigator({ onControllerChanged, initialRoute }) {
  return (
    <Stack.Navigator
      initialRouteName={initialRoute}
      screenListeners={{
        state: (e) => {
          const controller =
            e.data.state.routes[e.data.state.routes.length - 1].name;
          if (onControllerChanged) {
            onControllerChanged(controller);
          }
        },
      }}
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name={routes.dashboard}
        component={DashboardController}
        options={{ title: "MARITIME | Dashboard" }}
      />

      <Stack.Screen
        name={routes.broadcast}
        component={BroadcastController}
        options={{ title: "MARITIME | Broadcast" }}
      />

      <Stack.Screen
        name={routes.settings}
        component={SettingsController}
        options={{ title: "MARITIME | Settings" }}
      />

      <Stack.Screen
        name={routes.systemmessage}
        component={SystemMessageController}
        options={{ title: "MARITIME | System Message" }}
      />

      <Stack.Screen
        name={routes.message}
        component={MessageController}
        options={{ title: "MARITIME | Message" }}
      />

      <Stack.Screen
        name={routes.messagev2}
        component={MessageV2Controller}
        options={{ title: "MARITIME | Message" }}
      />

      <Stack.Screen
        name={routes.chat}
        component={ChatController}
        options={{ title: "MARITIME | Chat" }}
      />

      <Stack.Screen
        name={routes.dialpad}
        component={DialpadController}
        options={{ title: "MARITIME | Dialpad" }}
      />

      <Stack.Screen
        name={routes.omnichannel}
        component={OmniChannelController}
        options={{ title: "MARITIME | Omnichannel" }}
      />

      <Stack.Screen
        name={routes.omnichannelchat}
        component={OmniChannelChatController}
        options={{ title: "MARITIME | Omnichannel Chat" }}
      />

      <Stack.Screen
        name={routes.voicemail}
        component={VoicemailController}
        options={{ title: "MARITIME | Voicemail" }}
      />

      <Stack.Screen
        name={routes.marketing}
        component={MarketingController}
        options={{ title: "MARITIME | Marketing" }}
      />

      <Stack.Screen
        name={routes.health}
        component={MarketingHealthController}
        options={{ title: "MARITIME | Health" }}
      />

      <Stack.Screen
        name={routes.crewapp}
        component={MarketingCrewAppController}
        options={{ title: "MARITIME | Crew App" }}
      />

      <Stack.Screen
        name={routes.guestapp}
        component={MarketingGuestAppController}
        options={{ title: "MARITIME | Guest App" }}
      />

      <Stack.Screen
        name={routes.pbxsystem}
        component={MarketingPBXSystemController}
        options={{ title: "MARITIME | PBX System" }}
      />

      <Stack.Screen
        name={routes.starlink}
        component={MarketingController}
        options={{ title: "MARITIME | Starlink" }}
      />

      <Stack.Screen
        name={routes.notFound}
        component={NoFoundController}
        options={{ title: "MARITIME | No Found" }}
      />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default AppNavigator;
