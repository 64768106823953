export const linking = {
  config: {
    screens: {
      marketing: "",
      health: "/health",
      guestapp: "/guestapp",
      crewapp: "/crewapp",
      pbxsystem: "/pbxsystem",
      auth: "/auth",
      settings: "/settings",
      broadcast: "/broadcast",
      systemmessage: "/systemmessage",
      message: "/message",
      messagev2: "/messagev2",
      chat: "/chat/:id",
      dialpad: "/dialpad",
      omnichannel: "/omnichannel",
      omnichannelchat: "/omnichannelchat/:channel/:destination",
      dashboard: "/dashboard",
      voicemail: "/voicemail",
      starlink: "/starlink",

      notfound: "*",
    },
  },
};

const routes = {
  marketing: "marketing",
  health: "health",
  guestapp: "guestapp",
  crewapp: "crewapp",
  pbxsystem: "pbxsystem",
  auth: "auth",
  settings: "settings",
  broadcast: "broadcast",
  systemmessage: "systemmessage",
  message: "message",
  messagev2: "messagev2",
  chat: "chat",
  dialpad: "dialpad",
  omnichannel: "omnichannel",
  omnichannelchat: "omnichannelchat",
  dashboard: "dashboard",
  voicemail: "voicemail",
  starlink: "starlink",
  notFound: "notfound",
};

export default routes;
