import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { View, StyleSheet } from "react-native";
import NtTextInput from "../../components/NtTextInput";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";

function NtChatInputController({ containerStyle, onSend, ...otherProps }, ref) {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    // https://stackoverflow.com/questions/43953791/calling-functions-from-other-components-in-react-native
    // each key is connected to `ref` as a method name
    // they can execute code directly, or call a local method
    // method1: () => { localMethod1() },

    setLoading: (loading) => {
      setLoading(loading);
    },

    clear: () => {
      setMessage("");
    },
  }));

  return (
    <View style={[styles.container, containerStyle]}>
      <View
        style={{ flex: 1, alignItems: "flex-end", justifyContent: "center" }}
      >
        <NtTextInput
          containerStyle={{ flex: 1, borderRadius: 30 }}
          type={"text"}
          autoCapitalize="none"
          returnKeyType="search"
          textColor={colors.darkest}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          {...otherProps}
        />

        <View
          style={{
            position: "absolute",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <NtTouchableEffect
            style={[
              styles.button,
              message ? { backgroundColor: colors.blue } : {},
            ]}
            onPress={() => {
              if (onSend) {
                onSend(message);
              }
            }}
          >
            {!loading && (
              <NtMaterialIcon name="send" size={25} color={"white"} />
            )}
            {loading && <NtActivityIndicator size="small" color="white" />}
          </NtTouchableEffect>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    height: 40,
    width: 40,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.lighGray,
    marginLeft: 5,
  },
});

export default forwardRef(NtChatInputController);
