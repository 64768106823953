import React, { act } from "react";
import { View, StyleSheet, FlatList } from "react-native";
import NtText from "../../components/NtText";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import routes from "../../navigation/routes";
import NtDashboardItem from "../components/NtDashboardItem";

class DashboardController extends React.Component {
  state = {};

  options = [
    // {
    //   icon: "earth",
    //   title: "Broadcast",
    //   description: "Broadcast Messages to All Accounts",
    //   action: () => {
    //     this.props.navigation.navigate(routes.broadcast);
    //   },
    // },
    // {
    //   icon: "server",
    //   title: "System Message",
    //   description: "Send System message to specific account",
    //   action: () => {
    //     this.props.navigation.navigate(routes.systemmessage);
    //   },
    // },
    {
      icon: "message-text-outline",
      title: "Messages",
      description: "Send messages to user",
      action: () => {
        this.props.navigation.navigate(routes.messagev2);
      },
    },
    {
      icon: "voicemail",
      title: "Voicemail",
      description: "View/Play voicemails",
      action: () => {
        this.props.navigation.navigate(routes.voicemail);
      },
    },
    {
      icon: "share-variant",
      title: "OmniChannel",
      description: "Send External messages like Whatsup/SMS/etc...",
      action: () => {
        this.props.navigation.navigate(routes.omnichannel);
      },
    },
    {
      icon: "form-textbox-password",
      title: "Pin Dialing",
      description: "Manage Pin dialing",
      action: () => {
        this.props.navigation.navigate(routes.messagev2);
      },
    },
  ];

  renderRow = (item, index) => {
    return (
      <NtDashboardItem
        containerStyle={{ marginRight: 20, marginTop: 20, flex: 1 }}
        key={item.title}
        option={item}
        onPress={() => {
          item.action();
        }}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        renderTitleView={<View />}
        showNavigation={false}
        showFooter={true}
      >
        <FlatList
          style={{ flex: 1 }}
          data={this.options}
          numColumns={3}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => this.renderRow(item, index)}
          onEndReachedThreshold={0.5}
        />
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default DashboardController;
