import React, { useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtMessageChatInputController from "./NtMessageChatInputController";
import { sendBroadcast, sendSystemMessage } from "../../api/message";
import Logger from "../../common/utils/Logger";
import { showToast } from "../../common/utils/ControllerUtils";

function NtMessageUserSysMessageModal({ user }) {
  const inputRef = useRef();

  const handleBroadcaseSend = async (message) => {
    inputRef?.current?.setLoading(true);
    const response = await sendSystemMessage(message, user?.number);

    if (response.ok) {
      showToast("Success", "Successfully sent System Message");
    }

    inputRef?.current?.setLoading(false);
    inputRef?.current?.clear();
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>System Message</NtText>

      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Send message to user {user.name} ({user.number})
      </NtText>

      <NtMessageChatInputController
        ref={inputRef}
        enabled={true}
        containerStyle={{ marginTop: 30 }}
        onSend={(message) => {
          handleBroadcaseSend(message);
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
});

export default NtMessageUserSysMessageModal;
