const settings = {
  apiUrl: "https://cunnard-svc.ntlk.co/v3",
  apiAuth: "bmNsOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=",

  stompUrl: "wss://cunnard-svc.ntlk.co:15673/ws",
  messagingService: "nettalkmaritime.com",

  apiNotificationUrl: "https://api.mds.nettalk.com",
  apiNotificationAuth: "b3NjYXJhOnBhc3N3b3JkMUE=",

  apiNoderedUrl: "http://199.193.189.186:1880/api/v1",
  apiNoderedAuth: "alskjao83761jsUUy",

  pageSizeLoad: 25,
  mobileSize: 880,
};

export default settings;
