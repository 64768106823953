import React, { useState, useImperativeHandle, forwardRef } from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import TextareaAutosize from "react-textarea-autosize";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtText from "../../components/NtText";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import NtMessageChatInputSendButton from "./NtMessageChatInputSendButton";

function NtMessageChatInputController(
  { containerStyle, onSend, enabled },
  ref
) {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    // https://stackoverflow.com/questions/43953791/calling-functions-from-other-components-in-react-native
    // each key is connected to `ref` as a method name
    // they can execute code directly, or call a local method
    // method1: () => { localMethod1() },

    setLoading: (loading) => {
      setLoading(loading);
    },

    clear: () => {
      setMessage("");
    },
  }));

  const isRequired = () => {
    return message && enabled;
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <TextareaAutosize
        style={{
          fontSize: 13,
          borderColor: colors.lighestGray,
          borderWidth: 0,
          marginTop: 10,
          borderRadius: 10,
          outlineStyle: "none",
          resize: "none",
        }}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
        value={message}
        minRows={4}
        maxRows={10}
        placeholder="Write a message"
        draggable={false}
      />

      <View style={{ alignItems: "flex-end" }}>
        <NtMessageChatInputSendButton
          enabled={isRequired()}
          loading={loading}
          onPress={() => {
            if (onSend && isRequired()) {
              onSend(message);
            }
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colors.lighestGray,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    borderRadius: 8,
  },
});

export default forwardRef(NtMessageChatInputController);
