import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";

function NtDashboardItem({ option, onPress, containerStyle }) {
  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={() => {
        if (onPress) {
          onPress(option);
        }
      }}
    >
      <View
        style={{
          backgroundColor: colors.blue,
          padding: 8,
          borderRadius: 20,
        }}
      >
        <NtMaterialIcon name={option.icon} color={"white"} />
      </View>

      <View style={{ marginLeft: 12, flex: 1 }}>
        <NtText style={styles.title}>{option.title}</NtText>
        <NtText style={[styles.description, { marginTop: 3 }]}>
          {option.description}
        </NtText>
      </View>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 8,
    padding: 10,
    maxWidth: 300,
  },
  title: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.lighGray,
  },
  description: {
    fontSize: 12,
    fontWeight: "400",
    color: colors.lighGray,
  },
});

export default NtDashboardItem;
