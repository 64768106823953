import React from "react";
import { View, StyleSheet } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";
import NtModal from "../../components/NtModal";
import NtOmniChannelStartConversationModal from "../components/NtOmniChannelStartConversationModal";
import routes from "../../navigation/routes";

class OmniChannelController extends React.Component {
  state = {};

  startConversationModalRef = React.createRef();

  renderConversationModal = () => {
    return (
      <NtModal
        ref={this.startConversationModalRef}
        renderBody={
          <NtOmniChannelStartConversationModal
            onCreate={(params) => {
              this.startConversationModalRef?.current?.dismiss();
              // this.handleMessageSend();
              this.props.navigation.navigate(routes.omnichannelchat, {
                channel: params.channel.id,
                destination: params.destination,
              });
            }}
            onCancel={() => {
              this.startConversationModalRef?.current?.dismiss();
            }}
          />
        }
      />
    );
  };

  renderCreateNewConversation = (containerStyle) => {
    return (
      <View
        style={[{ flexDirection: "row", alignItems: "center" }, containerStyle]}
      >
        <NtText
          style={{ color: colors.darkest, fontSize: 15, fontWeight: "600" }}
        >
          Start New Conversation
        </NtText>
        <NtTouchableEffect
          style={{
            width: 30,
            height: 30,
            borderRadius: 15,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: colors.blue,
            marginLeft: 10,
          }}
          onPress={() => {
            this.startConversationModalRef?.current?.show();
          }}
        >
          <NtMaterialIcon name="plus" color="white" />
        </NtTouchableEffect>
      </View>
    );
  };

  render() {
    return (
      <NtRootPageContainer
        showNavigation={false}
        showBackButton={true}
        showFooter={true}
        pageTitle={"Omnichannel"}
      >
        {this.renderCreateNewConversation({ marginTop: 20 })}
        {this.renderConversationModal()}
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default OmniChannelController;
