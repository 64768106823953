import React from "react";
import { View, StyleSheet } from "react-native";
import BaseController from "../../common/base/BaseController";
import NtSearchBar from "../../components/NtSearchBar";
import NtActiveFiltersController from "../../components/NtActiveFiltersController";

class BaseMaritimeController extends BaseController {
  state = {};

  getParentState() {
    return this.state;
  }
}

export default BaseMaritimeController;
