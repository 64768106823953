import client, { parseQueryParams } from "./client";
import { generateFullUser } from "../maritime/utils/MessagingClient";
import Logger from "../common/utils/Logger";
import MessageItem from "../maritime/utils/Message/MessageItem";

const endpoint = "/chat/history";

export const fetchChats = (id) => {
  return client.get(endpoint + "?conversationId=" + id);
};

export const fetchHistory = (from, to) => {
  const endpoint =
    "/chat/history" +
    parseQueryParams({
      user: `${generateFullUser(from)}`,
    });

  return client.get(endpoint);
};

const messageFromPayload = (payload, me, receiver) => {
  let message = new MessageItem(null, null, null, payload);

  if (message.mSender == me && !message.mAddress?.includes(":")) {
    //will handle outgoing....
    message.mType = 1;
    message.mAddress = receiver;
  } else if (message.mAddress?.includes(":")) {
    //will handle the group....
    if (message.mSender == me) {
      message.mType = 1;
    }
  }
  return message;
};

export const fetchConversations = async (user) => {
  const me = generateFullUser(user);
  let response = await client.get(
    `/chat/history?user=${me}&page_size=50&newestOnly=yes`
  );

  if (response.ok && response.data?.total_items > 0) {
    response.data = response.data?._embedded?.chat_history.map((each) => {
      return messageFromPayload(each.payload, me, each?.receiver);
    });
  } else {
    response.data = [];
  }

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const fetchHistoryv2 = async (user, to) => {
  //will first pick the messages
  let response = await client.get(
    `/chat/history?user=${user}&page_size=50&newestOnly=yes`
  );

  if (response.ok && response.data?.total_items > 0) {
    //will first convert the response (payloads) to messge obbjects
    const msgObjects = response.data?._embedded?.chat_history.map((each) => {
      return messageFromPayload(each.payload, user, each?.receiver);
    });

    //will select which one is the one that belongs to the convesation...
    const message = msgObjects.find((each) => each.mAddress == to);

    if (message) {
      response = await client.get(
        `/chat/history?user=${user},${to}&page_size=50&skipFromMessageId=${message?.mMessageId}&sort=creationdate`
      );

      if (response.ok) {
        response.data = response?.data?._embedded?.chat_history?.map((each) => {
          return messageFromPayload(each.payload, user, each?.receiver);
        });
        response.data = [...response?.data, message];
      }
    } else {
      response.data = [];
    }
  } else {
    response.data = [];
  }

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};
