import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtMessageSendController from "../components/NtMessageSendController";
import NtUsersController from "../components/NtUsersController";
import Logger from "../../common/utils/Logger";
import NtModal from "../../components/NtModal";
import TextareaAutosize from "react-textarea-autosize";
import colors from "../../config/colors";
import NtButton from "../../components/NtButton";
import { sendSystemMessage } from "../../api/message";
import BaseMaritimeController from "./BaseMaritimeController";
import { showToast } from "../../common/utils/ControllerUtils";
import NtMessageUserSysMessageModal from "../components/NtMessageUserSysMessageModal";

class SystemMessageController extends BaseMaritimeController {
  sendModalRef = React.createRef();

  state = {
    ...this.getParentState(),
    selected: null,
    message: "",
    sending: false,
    searchFilter: null,
  };

  handleSend = async () => {
    console.log("Will send to one");

    if (!this.state.message) {
      // this.showToast("Oops..", "Please enter message to send", "error");
      return;
    }

    this.setState({ sending: true });

    const response = await sendSystemMessage(
      this.state.message,
      this.state.selected.number
    );
    if (response.ok) {
      showToast(
        "Message Sent",
        `Successfully sent message to ${this.state.selected.number}`
      );
      this.sendModalRef.current.dismiss();
    } else {
      showToast(
        "Message Sent",
        `Successfully sent message to ${this.state.selected.number}`,
        "error"
      );
    }

    this.setState({ sending: false, message: "" });
  };

  handleSearch = (text) => {
    Logger("Handling search for text: ", text);

    this.setState({
      searchText: text,
      searchFilter: text ? { firstname: text } : null,
    });
  };

  renderModal = () => {
    return (
      <NtModal
        ref={this.sendModalRef}
        renderBody={
          <NtMessageUserSysMessageModal user={this.state?.selected} />
        }
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        showNavigation={false}
        showFooter={true}
        showBackButton={true}
        pageTitle={"System Message"}
      >
        <View>
          <NtUsersController
            key={this.state.searchText}
            searchFilter={this.state.searchFilter}
            onPress={(user) => {
              Logger("Clicking on the user", user);
              this.setState({ selected: user });
              this.sendModalRef.current?.show();
            }}
          />
        </View>
        {this.renderModal()}
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  modalTitle: {
    fontSize: 16,
    fontWeight: "700",
    color: colors.lighGray,
  },
});

export default SystemMessageController;
