import Logger from "../../common/utils/Logger";
import { Client } from "@stomp/stompjs";
import MessageItem from "./Message/MessageItem";
import settings from "../../config/settings";

const appKey = "WebKey";

export const generateFullUser = (user) => {
  if (user && !user.toString().includes("@")) {
    return `${user}@${settings.messagingService}`;
  }
  return user;
};

class MessagingClient {
  constructor() {
    //will create a singleton
    if (MessagingClient._instance) {
      return MessagingClient._instance;
    }

    MessagingClient._instance = this;
    this.client = new Client();
    this.user = null;
    this.isConnected = false;
    this.subscription = null;
    this.connectionCallback = [];
  }

  init = (user) => {
    this.user = user;
    this.client.configure({
      brokerURL: global.stompUrl,
      connectHeaders: {
        login: generateFullUser(user.id),
        passcode: user.password,
        host: "ezlinq",
        appKey: appKey,
      },
      reconnectDelay: 2000,
      heartbeatIncoming: 0,
      heartbeatOutgoing: 20000,

      onConnect: () => {
        this.isConnected = true;
        this.delegateOnConnect();
      },

      onStompError: (frame) => {
        this.isConnected = false;
        this.delegateOnDisconnect();
      },
      onDisconnect: (frame) => {
        this.isConnected = false;
        this.delegateOnDisconnect();
      },
      onWebSocketClose: (frame) => {
        this.isConnected = false;
        this.delegateOnDisconnect();
      },
      debug: (str) => {
        Logger("--STOMP debug ", str);
      },
      onUnhandledMessage: (msg) => {
        Logger("STOMP unhandle message ", msg);
      },
    });
  };

  client = () => {
    return this.client;
  };

  //callback for different controllers
  addConnectionCallback = (ballback) => {
    if (this.connectionCallback.indexOf(ballback) < 0) {
      this.connectionCallback.push(ballback);
    }
  };

  removeConnectionCallback = (ballback) => {
    var index;
    if ((index = this.connectionCallback.indexOf(ballback)) >= 0) {
      this.connectionCallback.splice(index, 1);
    }
  };

  //privates...
  delegateOnConnect = () => {
    if (this.connectionCallback.length > 0) {
      this.connectionCallback.map((each) => {
        each({ connected: true });
      });
    }
  };

  delegateOnDisconnect = () => {
    if (this.connectionCallback.length > 0) {
      this.connectionCallback.map((each) => {
        each({ connected: false });
      });
    }
  };

  connect = () => {
    this.client.activate();
  };

  disconect = () => {
    this.client.deactivate();
  };

  subscribe = (user, onMessageReceived) => {
    this.subscription = this.client.subscribe(
      `/exchange/messages/${generateFullUser(user.id)}`,
      (message) => {
        const messageJson = JSON.parse(message.body);
        if (messageJson.payload === "message") {
          const msg = new MessageItem(null, null, null, messageJson);
          Logger("Message Received: ", msg);
          onMessageReceived(msg);
        }
      },
      { appKey: user.id }
    );
  };

  unsubscribe = () => {
    this.subscription?.unsubscribe();
  };

  send = (message, to) => {
    message.setTopic(to);
    this.client.publish({
      destination: `/exchange/messages/${to}`,
      body: JSON.stringify(message.getData()),
    });
  };

  sendMessage = (message, record, onMessageSent) => {
    if (record.isGroup()) {
      Logger("Group detected will send as group...");

      const participants = record.getAddress()?.split(":");
      for (let x = 0; x < participants.length; x++) {
        //will skip sending the message to myself.....
        if (!participants[x].includes(this.user?.id?.toString())) {
          this.send(message, participants[x]);
        }
      }
    } else {
      Logger("Will send message to", record.getAddress());
      this.send(message, record.getAddress());
    }

    onMessageSent(message);
  };
}

export default MessagingClient;
