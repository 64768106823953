import React, { useState } from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import { Feather } from "@expo/vector-icons";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtDevider from "../../components/NtDevider";
import NtTextInput from "../../components/NtTextInput";
import { omnichanneltypes } from "../data/messagechanneltypes";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";

function NtOmniChannelStartConversationModal({ onCancel, onCreate }) {
  const [channelSelection, setChannelSelection] = useState(null);
  const [destination, setDestination] = useState("");

  const isRequired = () => {
    // return channelSelection && destination && /^[0-9]{11}$/.test(destination);
    return channelSelection && destination;
  };

  const renderChannelRow = (item, index) => {
    return (
      <NtTouchableEffect
        key={item.id}
        style={{}}
        onPress={() => {
          setChannelSelection(item);
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <NtMaterialIcon name={item.icon} color={colors.darker} />
          {/* {item.id == "local" && (
            <View>
              <Image
                style={{ width: 20, height: 20, resizeMode: "contain" }}
                source={require("../../assets/images/silverseas-icon.png")}
              />
            </View>
          )} */}
          <NtText style={[styles.channelTitle, { marginLeft: 10, flex: 1 }]}>
            {item.title}
          </NtText>
          {channelSelection?.id == item.id && (
            <NtMaterialIcon name="check" color={colors.darkest} />
          )}
        </View>

        <NtDevider />
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>New Conversation</NtText>

      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Create new conversation channel
      </NtText>

      <View style={{ marginTop: 30 }}>
        <NtText style={[styles.sectionTitle, { marginBottom: 8 }]}>
          Enter Destination
        </NtText>
        <NtTextInput
          bgColor={colors.lighestGray}
          placeholder="Destination"
          value={destination}
          onChangeText={(text) => setDestination(text)}
          containerStyle={{ flex: 1, borderRadius: 30 }}
          type={"text"}
          autoCapitalize="none"
          returnKeyType="search"
          textColor={colors.darkest}
        />

        <NtText
          style={[styles.sectionTitle, { marginTop: 15, marginBottom: 8 }]}
        >
          Select Messaging Channel
        </NtText>
        {omnichanneltypes.map((each, index) => renderChannelRow(each, index))}
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
        <NtModalActiveButton
          containerStyle={{ marginLeft: 20 }}
          title={"create"}
          onPress={() => {
            if (onCreate && isRequired()) {
              onCreate({ channel: channelSelection, destination: destination });
            }
          }}
          enabled={isRequired()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  sectionTitle: {
    color: colors.darkest,
    fontSize: 14,
    fontWeight: "600",
  },
  channelTitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "400",
  },
});

export default NtOmniChannelStartConversationModal;
