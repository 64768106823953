import React from "react";
import { View, StyleSheet } from "react-native";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import Fontisto from "react-native-vector-icons/Fontisto";
import NtText from "../../components/NtText";

function NtChannelIcon({ size = 23, channel, color, containerStyle }) {
  const renderIcon = () => {
    if (channel?.id == "viber") {
      return (
        <Fontisto
          name={channel?.icon}
          size={size}
          color={color || channel?.color}
        />
      );
    } else if (channel?.id == "sms") {
      return (
        <View>
          <NtText
            style={{
              fontSize: 12,
              fontWeight: "700",
              color: color ?? channel?.color,
            }}
          >
            SMS
          </NtText>
        </View>
      );
    } else {
      return (
        <NtMaterialIcon
          name={channel?.icon}
          color={color || channel?.color}
          size={size}
        />
      );
    }
  };
  return <View style={[styles.container, containerStyle]}>{renderIcon()}</View>;
}

const styles = StyleSheet.create({
  container: {},
});

export default NtChannelIcon;
