import React from "react";
import { View, StyleSheet, ScrollView, FlatList } from "react-native";
import NtText from "../../components/NtText";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import MessagingClient from "../utils/MessagingClient";
import Logger from "../../common/utils/Logger";
import RecordItem from "../utils/Message/RecordItem";
import MessageItem from "../utils/Message/MessageItem";
import BaseController from "../../common/base/BaseController";
import colors from "../../config/colors";
import NtConnectionStatus from "../components/NtConnectionStatus";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import { fetchHistory } from "../../api/chat";
import NtchatItem from "../components/NtchatItem";
import NtChatInputController from "../components/NtChatInputController";
import { omnichanneltypes } from "../data/messagechanneltypes";
import NtMaterialIcon from "../../components/NtMaterialIcon";

class OmniChannelChatController extends BaseController {
  state = {
    data: [],
    messageText: "",
    isSending: false,
    isOnline: false,
    isFetchingHistory: false,
  };

  client = new MessagingClient();

  componentDidMount() {
    if (this.client.isConnected) {
      this.handleSubscribe();
    } else {
      this.client.addConnectionCallback((connection) => {
        Logger("Connection callback: ", connection);
        if (connection.connected) {
          this.handleSubscribe();
        }
      });
    }
  }

  handleHistyFetch = async () => {
    this.setState({ isFetchingHistory: true });
    const response = await fetchHistory(
      this.context.user.id,
      this.props.route.params.id
    );
    Logger("--- here is the history: ", response);

    // if (response.ok && Array.isArray(response.data._embedded.chat_history)) {
    //   var messages = [];
    //   response.data._embedded.chat_history.map((each) => {
    //     messages = [...messages, each.payload];
    //   });

    //   // will handle the to for the message:
    //   if (messages.length > 0) {
    //     const messageObj = messages[0];
    //     if (messageObj) {
    //       this.setState({
    //         recepients: [
    //           ...this.state.recepients,
    //           messageObj.address.substr(0, messageObj?.address.indexOf("@")),
    //         ],
    //       });
    //     }
    //   }

    //   console.log("Here is the history", messages);

    //   //finally will set all the messages.....
    //   this.setState({ messages: messages });

    //   if (messages.length > 5) {
    //     await this.delay(200);
    //     // this.messageListRef.current.scrollToEnd();
    //   }
    // }
    // this.setState({ loading: false });
    this.setState({ isFetchingHistory: false });
  };

  handleSubscribe = () => {
    this.client.subscribe(this.context.user, this.onMessageReceived);
    this.setState({ isOnline: true });
  };

  handleMessageSend = (messageText) => {
    this.setState({ isSending: true });
    // const record = new RecordItem(
    //   this.props.route.params.destination.toString()
    // );
    const record = new RecordItem("sms@sys.nettalk.com");
    const message = new MessageItem(
      this.context.user,
      record.getAddress(),
      messageText
    );

    this.client.sendMessage(message, record, this.onMessageSent);
  };

  onMessageSent = (message) => {
    Logger("Message sent", message);
    this.setState({
      messageText: "",
      data: [...this.state.data, message],
      isSending: false,
    });
  };

  onMessageReceived = (message) => {
    Logger("Message Received ", message);

    this.setState({
      data: [...this.state.data, message],
    });
  };

  renderRow = (item, index) => {
    return <NtchatItem key={item.mMessageId + index} item={item} />;
  };

  renderHeader = () => {
    const channel = omnichanneltypes.find(
      (each) => each.id == this.props.route.params.channel
    );

    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: 25,
          marginBottom: 20,
        }}
      >
        <NtText style={styles.headerText}>
          Conversation with {this.props.route.params.destination.toString()}{" "}
          <NtConnectionStatus containerStyle={{ marginLeft: 6 }} />
        </NtText>
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 6 }}
        >
          <NtMaterialIcon name={channel.icon} color={colors.lighGray} />
          <NtText
            style={{
              color: colors.lighGray,
              fontSize: 14,
              fontWeight: "400",
              marginLeft: 6,
            }}
          >
            {channel.title}
          </NtText>
        </View>
        {this.state.isFetchingHistory && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 8,
            }}
          >
            <NtText style={styles.headerText}>
              Loading messages from history{" "}
            </NtText>
            <NtActivityIndicator color={colors.lighGray} size={"small"} />
          </View>
        )}
      </View>
    );
  };

  render() {
    return (
      <NtRootPageContainer
        renderTitleView={<View />}
        showNavigation={false}
        showFooter={false}
        scrollEnabled={false}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          <ScrollView
            ref={this.scrollViewRef}
            contentContainerStyle={{ flexGrow: 1, paddingBottom: 10 }}
            showsVerticalScrollIndicator={false}
            onContentSizeChange={() => {
              if (!this.allowScrollToBottom) {
                return;
              }
              this.scrollViewRef?.current.scrollToEnd({
                animated: this.firstTime ? false : true,
              });
              if (this.state.data.length > 0 && this.firstTime) {
                this.firstTime = false;
              }
            }}
          >
            {this.state.loading && this.state.data.length == 0 && (
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <NtActivityIndicator color={colors.blue} size="small" />
              </View>
            )}
            {!this.state.loading && this.state.data.length == 0 && (
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <NtText style={styles.nomessages}>Type message below</NtText>
              </View>
            )}
            {this.renderHeader()}
            {this.state.data.map((each, index) => this.renderRow(each, index))}
          </ScrollView>

          <NtChatInputController
            ref={this.chatInputRef}
            placeholder={"Message to send"}
            onSend={(message) => {
              this.handleMessageSend(message);
            }}
          />
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  headerText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.lighGray,
  },
  nomessages: {
    fontSize: 13,
    fontWeight: "500",
    color: colors.lighGray,
  },
});

export default OmniChannelChatController;
