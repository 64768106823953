import React, { useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { useMobile } from "../../hooks/useMobile";
import NtFooterBar from "./NtFooterBar";
import Lottie from "lottie-react";
import NtRootPageContainerHeader from "./NtRootPageContainerHeader";
import colors from "../../config/colors";
import NtRootPageNavigation from "./NtRootPageNavigation";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import { navigationRef } from "../../../App";
import routes from "../../navigation/routes";

function NtRootPageContainer({
  children,
  pageTitle,
  pageSubtitle,
  renderTitleView,
  renderFilter,
  showFooter = false,
  showNavigation = true,
  showSidePannel = false,
  showBackButton = false,
  sidePannelContainerStyle = 0,
  isLoading,
  scrollEnabled = true,
}) {
  const isMobile = useMobile();
  const maxWidth = 850;
  const sidePannelWidth = 245;

  const renderLottieLoading = () => {
    return null;
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        {/* <Lottie
          style={{ width: 250, heigth: 250 }}
          animationData={require("../../lottie/lottie_news_load_animation.json")}
          loop={true}
        /> */}
      </View>
    );
  };

  return (
    <View style={[styles.container]}>
      <ScrollView
        scrollEnabled={scrollEnabled}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <View
          style={{ flex: 1, justifyContent: "center", flexDirection: "row" }}
        >
          <View
            style={{
              padding: isMobile ? 15 : 20,
              flex: 1,
              maxWidth: maxWidth,
            }}
          >
            {showNavigation && (
              <NtRootPageNavigation
                containerStyle={{
                  paddingLeft: 25,
                  paddingRight: 25,
                  marginTop: 5,
                  marginBottom: 20,
                }}
              />
            )}
            {renderFilter && (
              <View style={{ marginTop: 10 }}>{renderFilter}</View>
            )}
            {!renderTitleView && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: renderFilter ? 30 : 0,
                }}
              >
                {showBackButton && (
                  <NtTouchableEffect
                    onPress={() => {
                      if (navigationRef?.current?.canGoBack()) {
                        navigationRef?.current.goBack();
                      } else {
                        navigationRef?.current?.navigate(routes.dashboard);
                      }
                    }}
                  >
                    <NtMaterialIcon
                      style={{ marginRight: 10 }}
                      name={"arrow-left"}
                      color={colors.lighGray}
                      size={25}
                    />
                  </NtTouchableEffect>
                )}

                <NtRootPageContainerHeader
                  title={pageTitle}
                  subtitle={pageSubtitle}
                  titleSize={isMobile ? 20 : 27}
                  subtitleSize={isMobile ? 18 : 18}
                />
              </View>
            )}
            {renderTitleView && renderTitleView}
            {/* will render the line before.... */}
            {!renderTitleView && (
              // <View style={{ height: 15 }} />
              <View
                style={{
                  width: "100%",
                  height: 1,
                  marginTop: 15,
                  backgroundColor: colors.lighestGray,
                }}
              />
            )}
            {isLoading && renderLottieLoading()}
            {!isLoading && children}
          </View>
          {!isMobile && showSidePannel && (
            <View
              style={[
                sidePannelContainerStyle,
                {
                  width: sidePannelWidth,
                  paddingRight: 10,
                  paddingLeft: 10,
                },
              ]}
            ></View>
          )}
        </View>

        {showFooter && <NtFooterBar />}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 0,
    backgroundColor: colors.lighestGray,
  },
});

export default NtRootPageContainer;
