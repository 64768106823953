import React from "react";
import { View, StyleSheet } from "react-native";
import routes from "../../navigation/routes";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtUsersController from "../components/NtUsersController";
import BaseMaritimeController from "./BaseMaritimeController";
import Logger from "../../common/utils/Logger";
import MessagingClient from "../utils/MessagingClient";

class MessageController extends BaseMaritimeController {
  state = {
    ...this.getParentState(),
    searchFilter: null,
  };

  client = new MessagingClient();

  componentDidMount() {}

  handleSearch = (text) => {
    Logger("Handling search for text: ", text);

    this.setState({
      searchText: text,
      searchFilter: text ? { firstname: text } : null,
    });
  };

  render() {
    return (
      <NtRootPageContainer
        showNavigation={false}
        showBackButton={true}
        showFooter={true}
        pageTitle={"Message"}
      >
        <View>
          <NtUsersController
            key={this.state.searchText}
            searchFilter={this.state.searchFilter}
            onPress={(user) => {
              this.props.navigation.navigate(routes.chat, { id: user.number });
            }}
          />
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default MessageController;
