import client, { parseQueryParams } from "./client";
import { generateFullUser } from "../maritime/utils/MessagingClient";
import Logger from "../common/utils/Logger";
import MessageItem from "../maritime/utils/Message/MessageItem";
import clientnodered from "./clientnodered";
import settings from "../config/settings";

export const fetchDirectory = async () => {
  const response = await clientnodered.get(
    `/directory?apiToken=${settings.apiNoderedAuth}`
  );
  if (response.ok) {
    response.data = response.data?.map((each) => {
      return {
        id: each.app_number,
        number: each.app_number,
        name: each.name,
        comments: each.comments,
        department: each.department,
        externalNumber: each.number,
      };
    });
  }

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};
